@import "../public/assets/scss/style.scss";

.transaction-table {
  margin-left: 50px;
  margin-top: 50px;
  margin-right: 50px;
}

.search-transaction {
  padding: 8px;
  border: 1px solid rgba(128, 128, 128, 0.291);
  border-radius: 5px;
  color: black;
  &:focus,
  &:hover {
    color: #000;
    outline: 1px solid #eee;
  }
}

.check-input {
  display: flex;
  align-items: center;
}

.white-space-nowrap {
  white-space: nowrap !important;
}

.switch-box {
  padding: 0;
  position: relative;
  width: calc(30px + (36 - 30) * ((100vw - 320px) / (1920 - 320)));
  height: calc(20px + (20 - 20) * ((100vw - 320px) / (1920 - 320)));
  appearance: none;

  &::before {
    content: "";
    position: absolute;
    background-color: rgba(211, 211, 211, 0.548);
    border: 1px solid rgba(0, 0, 0, 0.322);
    border-radius: 20px;
    width: 100%;
    height: 100%;
    transition: 0.3s;
  }

  &::after {
    cursor: pointer;
    content: "";
    position: absolute;
    top: 3px;
    left: 3px;
    width: calc(10px + (14 - 10) * ((100vw - 320px) / (1920 - 320)));
    height: calc(10px + (14 - 10) * ((100vw - 320px) / (1920 - 320)));
    transform: translateX(0px);
    border-radius: 100%;
    background-color: #198754;
    transition: all 0.3s ease-in-out;
  }

  &:checked {
    &::before {
      background-color: #198754;
    }
    &::after {
      transform: translateX(calc(26px + (16 - 26) * ((100vw - 320px) / (1920 - 320))));
      background-color: white;
    }
  }
}

// loader
.loader-wrapper {
  height: 350px;
  display: flex;
  align-items: center;
  justify-content: center;

  /* HTML: <div class="loader"></div> */
  .loader {
    width: fit-content;
    font-weight: bold;
    font-family: monospace;
    font-size: 21px;
    color: #0000;
    background: linear-gradient(90deg, #c02942 calc(50% + 0.5ch), #000 0) right/calc(200% + 1ch) 100%;
    -webkit-background-clip: text;
    background-clip: text;
    animation: l7 2s infinite steps(11);
  }
  .loader:before {
    content: "Loading...";
  }
}

@keyframes l7 {
  to {
    background-position: left;
  }
}
// Tooltip

.tooltip {
  --bs-tooltip-max-width: unset !important;
  width: max-content !important;

  .tooltip-inner {
    background-color: #000000;
    margin-left: 6px;
    color: #ffffff;
    cursor: pointer;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
  .tooltip-arrow {
    margin-left: 5px;
  }
}

.bs-tooltip-auto[data-popper-placement^="top"] {
  .tooltip-arrow {
    &::before {
      border-top-color: rgba(#000000, 1);
    }
  }
}

.bs-tooltip-auto[data-popper-placement^="bottom"] {
  .tooltip-arrow {
    &::before {
      border-bottom-color: rgba(#000000, 1);
    }
  }
}

.bs-tooltip-auto[data-popper-placement^="left"] {
  .tooltip-arrow {
    &::before {
      border-left-color: rgba(#000000, 1);
    }
  }
}

.bs-tooltip-auto[data-popper-placement^="right"] {
  .tooltip-arrow {
    &::before {
      border-right-color: rgba(#000000, 1);
    }
  }
}

.no-found {
  height: 300px;
  padding-block: 150px !important;
}

//  import csv box

.modal {
  .modal-header {
    border-bottom: none;
    padding-bottom: 0;

    h5 {
      font-weight: 500;
      font-size: 18px;
    }

    .btn-close {
      display: none;
    }
  }
  .modal-content {
    padding: 20px;
    border-radius: 20px;

    .modal-body {
      .content-section {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        height: 300px;
        border: 1px dashed rgb(0, 167, 111);
        border-radius: 20px;
        margin-bottom: 10px;

        .browse-file {
          position: relative;
          cursor: pointer;

          input {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
          }
        }
      }
      .import-file {
        font-weight: 500;
        padding-left: 5px;

        &:hover {
          text-decoration: underline;
        }
      }
    }
    .modal-footer {
      border: none;
      padding-top: 0;
    }
  }
}

.import-list {
  border: 1px dashed lightgray;
  padding: 10px;
  border-radius: 10px;
}

// button spinner

.spinning {
  padding-right: 40px !important;

  &::before {
    content: "";
    position: absolute;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    right: 10px;
    border: 2px solid rgba(black, 0.29);
    border-right: 3px solid red;
    animation: rotate360 0.5s infinite linear;
  }
}

.loader-btn{
  margin: 0;
  button{
    display: flex;
    align-items: center;
    gap: 16px;
    font-weight: 500;
    font-size: 15px;
    text-transform: capitalize;
    padding: 7px 18px;
    margin-left: auto;

    .loader{
      content: "";
      position: relative;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      border: 2px solid rgba(255, 255, 255, 0.29);
      border-right: 3px solid #fff;
      animation: rotate360 0.9s infinite linear;
    }
  }
}

.clear-btn{
  margin: 0;
  font-size: 15px;
  padding: 7px 18px;
}

@keyframes rotate360 {
  100% {
  transform: rotate(360deg);
  }
 }