// // Auth css

// .login-section{
//     width: 100%;
//     height: 100%;
//     position: relative;
//     .login-content{
//         height: 100vh;
//         margin: 50px;
//         .login-main{
//             height: 100%;
//             h2{
//                 color: $white;
//                 font-size: 32;
//             }
//             .auth-content{

//             }
//         }
//     }
// }

/**=====================
  Auth CSS starts
==========================**/

.login-section {
  width: 100%;
  margin: 0 auto;
  position: relative;
  height: 100%;
  background-color: $dark-color;
  // background-image: url("../../images/auth-bg.jpg");
  // background-position: left;
  // background-repeat: no-repeat;
  // background-size: cover;

  .help-div {
    display: inline-block;
    position: absolute;
    bottom: 60px;
    right: 70px;
    background: white;
    border-radius: 100%;
    padding: 10px;
    z-index: 5;

    img {
      width: 26px;
      height: 26px;
    }
  }

  h2 {
    color: $white;
    text-align: center;
    margin-bottom: 30px;
  }

  .login-content {
    height: 100vh;
  }

  .login-main {
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 50px;
    width: 60%;
    margin: 0 auto;
    padding: 40px;
    justify-content: space-around;

    @media (max-width: 1599px) {
      width: 90%;
    }

    @media (max-width: 1399px) {
      width: 100%;
      padding: 24px;
    }

    @media (max-width: 1199px) {
      margin-left: unset;
      margin: auto;
    }

    @media (max-width: 575px) {
      padding: 20px;
    }

    .logo-wrapper {
      height: 60px;
      width: 176px;
      position: absolute;
      top: 100px;
      left: 40px;

      @media (max-width: 1399px) {
        left: 20px;
      }

      @media (max-width: 1199px) {
        top: 60px;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }

    .auth-card {
      .auth-title {
        h2 {
          font-size: calc(20px + (24 - 20) * ((100vw - 992px) / (1920 - 992)));
          font-weight: 700;
          margin-bottom: calc(20px + (40 - 20) * ((100vw - 992px) / (1920 - 992)));
          display: block;
        }

        .login-account {
          font-weight: 500;
          color: $gray;
          display: flex;
          align-items: center;
          gap: 4px;
          margin-bottom: 20px;

          a {
            color: $primary-color;
            font-size: 14px;
            line-height: 19px;
            font-weight: 600;
          }
        }
      }

      .form-group {
        position: relative;
        margin-bottom: 24px !important;
        svg {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 0;
          width: 18px;
          height: 18px;
        }
      }

      .btn {
        padding: 8px 24px;
        background-color: $primary-color;
        width: 100%;
        justify-content: center;
        font-size: 16px;
        font-weight: 700;
        letter-spacing: 1%;
        line-height: 20px;
        color: $white;
        border-radius: 22px;
        border: 1px solid $primary-color;
        //   position: absolute;
        //   left: 50%;
        //   transform: translateX(-50%);
        //   width: 70%;
        //   bottom: -20px;

        &:hover {
          background-color: $primary-color;
        }

        &.active,
        &:focus {
          background-color: $primary-color;
        }
      }
      .form-check {
        padding-inline: 30px;

        label {
          font-weight: 400;
          line-height: 19px;
        }
      }

      .forgot-pass {
        display: flex;
        align-items: center;
        gap: 6px;
        justify-content: space-between;

        a {
          font-size: 14px;
          font-weight: 500;
          letter-spacing: 0.5px;
          line-height: 17px;
          color: rgba($white, 0.4);

          &.text-dark {
            color: $gray;
          }
        }

        &.signup {
          margin-top: 50px;
          margin-bottom: -30px;
        }
      }
    }

    .social-media {
      display: flex;
      align-items: center;
      gap: 11px;
      justify-content: space-around;
      padding: 0;
      flex-wrap: nowrap;
      position: absolute;
      bottom: 60px;
      left: 40px;
      width: calc(100% - 80px);

      @media (max-width: 1399px) {
        left: 23px;
        width: calc(100% - 46px);
      }

      li {
        background-color: $white;
        border: 1px solid $gray;
        border-radius: 30px;
        width: 100%;

        a {
          display: flex;
          align-items: center;
          gap: 16px;
          color: $gray;
          font-size: 14px;
          font-weight: 700;
          line-height: 17px;
          padding: 8px 12px 8px 8px;

          img {
            width: 30px;
            height: 30px;
          }
        }
      }
    }
  }

  .auth-content {
    position: relative;
    background-color: $gray;
    border-radius: 22px;
    border: 1px solid $gray;
    padding: 25px;
    color: $white;
    // padding-bottom: 25px/;
  }
}
.toggle-password {
  position: absolute;
  right: 0;
  left: unset !important;
  top: 40px;
  svg {
    position: static !important;
  }
  &:before {
    position: absolute;
    top: -5px;
    right: 8px;
    content: "";
    height: 23px;
    width: 1px;
    transform: rotate(28deg);
    opacity: 0;
    visibility: hidden;
    background-color: rgba(255, 255, 255, 1);
  }
  &.show {
    &:before {
      opacity: 1;
      visibility: visible;
    }
  }
}

.form-control {
  background-color: transparent;
  border: none;
  padding: 6px 6px 6px 12px;
  padding-left: 30px;
  border-radius: 0;
  border-bottom: 1px solid rgba($white, 0.1);
  color: $white;

  &:focus {
    border-color: rgba($white, 0.45);
    color: $white;
    background-color: transparent !important;
    outline: none;
    box-shadow: none;
  }
  &:hover,
  &:active {
    color: $white;
    background-color: transparent;
    outline: none;
    box-shadow: none;
    border-color: rgba($white, 0.1);
  }
  &::placeholder {
    color: rgba($white, 0.4);
  }
}

.image-main {
  padding: 40px;
  padding-left: 0;
  width: 100%;
  height: 100%;

  .image-contain {
    //   background-image: url(../../images/svg/banner/freelancer-banner.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    border-radius: 20px;

    &.business-banner {
      // background-image: url(../../images/svg/banner/business-banner.svg);
    }

    .login-badge {
      display: flex;
      align-items: center;
      gap: 8px;
      position: absolute;
      top: calc(70px + (80 - 70) * ((100vw - 992px) / (1920 - 992)));
      right: calc(70px + (80 - 70) * ((100vw - 992px) / (1920 - 992)));
      background-color: $white;
      border-radius: 30px;
      padding: 8px 20px;
      font-size: 14px;
      font-weight: 700;
      line-height: 17px;
      letter-spacing: 0.5px;

      img {
        width: 18px;
        height: 18px;
      }
    }

    .image-title {
      font-size: 50px;
      font-weight: 800;
      line-height: 1.1;
      color: $primary-color;
      position: absolute;
      top: 110px;
      left: calc(50px + (60 - 50) * ((100vw - 992px) / (1920 - 992)));
      width: 63%;
    }

    .auth-content {
      position: absolute;
      bottom: 80px;
      left: calc(50px + (60 - 50) * ((100vw - 992px) / (1920 - 992)));
      width: 340px;
      padding: 10px;
      height: auto;
      display: flex;
      flex-direction: column;
      gap: 12px;
      align-items: flex-start;

      p {
        font-size: 18px;
        font-weight: 500;
        line-height: 26px;
        margin: 0;
      }
    }

    .quote {
      width: 40px;
      height: 40px;
    }

    .divider {
      width: 138px;
      height: 2px;
      background-color: $gray;
      margin-top: -4px;
    }

    .trusted {
      display: flex;
      align-items: center;
      gap: 4px;
      font-size: 16px;
      font-weight: 700;

      img {
        width: 16px;
        height: 16px;
      }
    }
  }
}

.auth-main {
  position: relative;

  .flower {
    position: fixed;
    right: 0;
    bottom: 0;
    width: 34%;
    z-index: -1;
  }

  .leaf {
    position: fixed;
    right: 0;
    bottom: 0;
    width: 26%;
    z-index: -1;
  }

  .auth-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    position: absolute;
    width: 100%;
    left: 0;
    //   padding: 0 $body-padding;
    //   top: $body-padding;

    .logo-wrapper {
      height: 60px;
      width: 176px;

      img {
        height: 100%;
        width: 100%;
      }
    }
  }

  .auth-body {
    .auth-body-title {
      display: flex;
      flex-direction: column;
      gap: 24px;
      padding-inline: 10px;

      .auth-sub-title {
        display: flex;
        align-items: center;
        justify-content: space-between;

        h1 {
          line-height: calc(20px + (55 - 20) * ((100vw - 320px) / (1920 - 320)));
          font-weight: 700;
          color: $gray;
        }

        .page-count {
          span,
          small,
          p {
            font-weight: 700;
            font-size: calc(18px + (20 - 18) * ((100vw - 320px) / (1920 - 320)));
            line-height: 25px;
            margin-bottom: 0;
          }
        }
      }

      p {
        font-size: 16px;
        font-weight: 500;
        color: $gray;
      }

      &.welcome {
        height: 100%;
        align-items: center;
        justify-content: center;

        .hifive {
          width: 200px;
          height: 200px;
        }
      }
    }
  }
}

.verification-form {
  text-align: center;

  .title {
    margin-bottom: 50px;

    h3 {
      span {
        color: $primary-color;
      }
    }
  }

  .otp-code {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    padding: 0;
    margin-top: 50px;

    .otp-field {
      border: 1px solid $gray;
      border-radius: 12px;
      background-color: $gray;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 69px;
      width: 69px;
      font-size: 32px;
      font-weight: 600;
      line-height: 40px;
      text-align: center;

      &:active,
      &.focus,
      &:hover,
      &:focus-visible {
        border: 1px solid $gray;
        outline: none;
      }

      &.invalid-field {
        border: 1px solid $gray;
        //   background-color: $ex  ;
        //   color: $error-dark-color;
      }
    }
  }

  .resend-code {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 30px;
    gap: 12px;

    p {
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      margin: 0;
      text-align: left;

      .sec {
        font-weight: 700;
      }
    }

    .resend {
      font-size: 14px;
      font-weight: 700;
      line-height: 17px;
      letter-spacing: 0.7px;
      white-space: nowrap;
    }
  }

  .action-btn {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    gap: calc(16px + (20 - 16) * ((100vw - 992px) / (1920 - 992)));

    .btn {
      width: 100%;
      border-radius: 25px;
      font-weight: 700;
      padding: 12px 32px;
      font-size: 16px;
      letter-spacing: 0.7px;
      line-height: 20px;
      justify-content: center;
      position: relative;

      .action-btn-image {
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  &.login-detail {
    text-align: start;

    .otp-code {
      justify-content: flex-start;
      margin-top: 20px;
      gap: 8px;

      .otp-field {
        &:active,
        &.focus,
        &:hover,
        &:focus-visible {
          border: 1px solid $gray;
          outline: none;
        }

        &.invalid-field {
          border: 1px solid $gray;
          // background-color: $ex    ;
          // color: $error-dark-color;
        }
      }
    }

    .resend-code {
      margin-inline: 0;
    }

    .action-btn {
      margin-top: 30px;

      .btn {
        margin: 0;
      }
    }
  }
}

.auth-footer-main {
  position: absolute !important;
  width: 100%;
  bottom: 30px;
  margin-inline: auto;
  max-width: 1140px;
  padding: 0 !important;
}

.auth-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  width: 100%;

  .btn {
    min-width: 260px;
    gap: 50px;
    justify-content: space-between;
  }
}

.form-check {
  padding: 0;
  min-height: unset;
  display: flex;
  align-items: center;
  gap: 6px;

  label {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    margin: 0;

    a {
      font-weight: 700;
      color: $dark-color;
    }
  }

  .form-check-input,
  .form-radio-input {
    width: 15px;
    height: 15px;
    cursor: pointer;
    position: relative;
    margin: 0;

    &:focus {
      box-shadow: none;
    }

    &:before {
      transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
      transform: rotate(-45deg) scale(0, 0);
      content: "";
      position: absolute;
      left: 3px;
      top: 3px;
      z-index: 1;
      width: 9px;
      height: 6px;
      border: 3px solid $primary-color;
      border-top-style: none;
      border-right-style: none;
    }

    &:after {
      content: "";
      position: absolute;
      top: -1px;
      left: -1px;
      width: 16px;
      height: 16px;
      background: $white;
      border: 1px solid $gray;
      cursor: pointer;
      border-radius: 2px;
    }

    &:hover {
      &:after {
        border-color: $primary-color;
      }
    }

    &:checked:before {
      transform: rotate(-45deg) scale(1, 1);
    }

    &:checked:after {
      border-color: $gray;
      background-color: $white;
    }
  }

  .form-radio-input {
    margin: 0;
    padding: 0;
    height: 10px;

    &:after {
      border-radius: 100%;
      left: 0;
      top: -3px;
    }

    &:before {
      border: none;
      background-color: $primary-color;
      border-radius: 100%;
      width: 12px;
      height: 12px;
      top: -1px;
      left: 2px;
      z-index: 1;
    }

    ~ label {
      color: $gray;
    }

    &:checked {
      &:after {
        outline: 1px solid $gray;
      }

      ~ label {
        font-weight: 500;
        color: $gray;
      }
    }
  }
}

.mb-3 {
  &.mb-0 {
    margin-bottom: 0 !important;
  }
}

// /**=====================
//   Auth CSS end
// ==========================**/
