
*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body{
    padding: 0;
    margin: 0;
    position: relative;
    height: 100%;
}

a{
    text-decoration: none;

}

ul{
    padding: 0;
    li{
        display: inline-block;
    }
}