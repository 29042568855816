/**=====================
  5.7 Sidebar CSS Start
==========================**/
%sidebar-after {
  position: absolute;
  content: "";
  top: 50%;
  width: 11px;
  // border-top: 1px solid $sidebar-dashed-border;
}
// default dashboard
.d-flex {
  &.media-wrapper {
    width: 100%;
    display: -webkit-flex;
    .flex-grow-1 {
      flex: unset;
    }
  }
  .flex-grow-1 {
    flex: unset;
  }
}
.heart {
  text-align: end;
}
%sidebar-before {
  position: absolute;
  content: '';
  // border: 1px dashed $sidebar-dashed-border;
  height: calc(100% - 12px);
  top: 5px;
  left: 12px;
  transition: height 0.5s;
  [dir="rtl"] & {
    left: unset;
    right: 12px;
  }
}
%horizontal-after {
  position: absolute;
  content: "";
  top: 49%;
  width: 5px;
  height: 1px;
  border-radius: 100%;
  // background-color: $semi-dark;
}
%for-animated-hover {
  // box-shadow: $main-header-shadow;
  transform: translateY(30px);
  opacity: 0;
  visibility: hidden;
  left: 0;
}
%for-animated-hover-box {
  opacity: 1;
  transform: translateY(0px);
  visibility: visible;
  border-radius: 5px;
  &:before {
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid $white;
    content: "";
    top: -7px;
    position: absolute;
    left: 10px;
    z-index: 2;
  }
  &:after {
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    // border-bottom: 7px solid $light-background;
    content: "";
    top: -7px;
    position: absolute;
    left: 10px;
    z-index: 1;
  }
}
.page-wrapper {
  position: relative;
  // &.horizontal-wrapper {
  //   .page-body-wrapper {
  //     .sidebar-wrapper {
  //       .sidebar-main {
  //         .sidebar-links {
  //           .simplebar-wrapper {
  //             .simplebar-mask {
  //               .simplebar-content-wrapper {
  //                 .simplebar-content {
  //                   li {
  //                     &:hover {
  //                       ul { 
  //                         display: block !important;
  //                         opacity: 1;
  //                         visibility: visible; 
  //                         li {
  //                           .nav-sub-childmenu {
  //                             display: none !important;
  //                             border-radius: 4px;
  //                             opacity: 0;
  //                             visibility: hidden;
  //                           }
  //                           &:hover {
  //                             .nav-sub-childmenu {
  //                               display: block !important;
  //                               visibility: visible;
  //                               position: absolute;
  //                               left: 200px;
  //                               [dir="rtl"] & {
  //                                 right: 200px;
  //                                 left: unset;
  //                               } 
  //                               opacity: 1 ;
  //                               background-color: $white;
  //                               top: -10px;
  //                               padding: 15px 0px 15px 0px;
  //                               box-shadow: 0 0 1px 0 #898989;
  //                             }
  //                           }
  //                         }
  //                       }
  //                     }
  //                   }
  //                 }
  //               }
  //             }
  //           }
  //         }
  //       }
  //     }
  //     .sidebar-wrapper {
  //       .sidebar-main {
  //         .sidebar-links {
  //           >div {
  //             height: 100% !important;
  //             width: 100% !important;
  //           }
  //         }
  //       }
  //     }
  //   }
  // }
  .page-header {
    max-width: 100vw;
    // position: $main-header-position;
    // top: $main-header-top;
    // z-index: $main-header-z-index;
    // transition: $sidebar-transition;
    background-color: $white;
    // box-shadow: $main-header-shadow-1;
    .header-wrapper {
      width: 100%;
      align-items: center;
      // padding: $header-wrapper-padding;
      position: relative;
      @media (max-width: 1550px) and (min-width: 1200px){
        padding: 16px 20px;
      }
      .serchinput {
        display: none !important;
        .serchbox {
          position: relative;
        }
        .search-form {
          top: 50px;
          position: absolute;
          transition: all linear 0.3s;
          right: -50px;
          transform: translateY(-35px) scaleY(0);
          opacity: 0;
          visibility: hidden;
          width: 180px;
          margin-left: 12px;
          @media (max-width: 480px) {
            right: -120px;
          }
          &.open {
            transform: translateY(0px) scaleY(1);
            opacity: 1;
            visibility: visible;
            transition: all linear 0.3s;
          }
          input {
            padding: 10px 15px;
            // background-color: $light;
          }
        }
      }
      .nav-right {
        justify-content: flex-end;
        ul {
          li {
            svg {
              // vertical-align: $header-wrapper-nav-icon-align;
              // width: $header-wrapper-nav-icon-size;
              // height: $header-wrapper-nav-icon-size;
              stroke: none;
              // fill: var(--theme-default);
              transition: all 0.5s ease;
              path {  
                // color: $header-wrapper-nav-icon-color;
              }
            }
            .mode {
              &.active {
                svg {
                  rotate: 180deg;
                }
              }
            }
          }
        }
        &.right-header {
          ul {
            li {
              .mode {
                cursor: pointer;
                text-align: center;
                i {
                  font-size: 18px;
                  font-weight: 600;
                  transition: all 0.3s ease;
                  &.fa-lightbulb-o {
                    font-weight: 500;
                    font-size: 22px; 
                    transition: all 0.3s ease;
                    animation: zoomIn 300ms ease-in-out;
                  }
                }
              }
              .header-search {
                cursor: pointer;
              }
              .profile-media {
                img{
                  width: 40px;
                  margin: 0px;
                }
                .flex-grow-1 {
                  margin-left: 10px;
                  span {
                    font-weight: 500;
                  }
                  p {
                    font-size: 12px;
                    line-height: 1;
                    // color: $theme-body-sub-title-color;
                  }
                }
              }
            }
          }
        }
        >ul {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          >li {
            padding: 9px 10px;
            display: inline-block;
            position: relative;
            // background-color: $light-color;
            border-radius: 50%;
            margin-right: 15px;
            transition: all 0.5s ease;
            cursor: pointer;
            [dir="rtl"] & {
              margin-right: unset;
              margin-left: 15px;
            }
            &:hover {
              background-color: rgba($primary-color, 0.1);
              transition: all 0.5s ease;
              svg {
                // fill: var(--theme-default);
                transition: all 0.5s ease;
              }
            }
            @media (max-width: 420px){
              padding: 6px 8px;
              margin-right: 5px;
            }
            &:last-child {
              background-color: unset !important;
              margin-right: 0;
              [dir="rtl"] & {
                margin-right: unset;
                margin-left: 0;
              }
            }
            &:nth-child(7) {
              @media (max-width: 630px){
                display: none;
              }
            }
            .badge {
              position: absolute;
              right: -15px;
              top: -15px;
              height: 16px;
              width: 16px;
              display: flex;
              align-items: center;
              font-size: 10px;
              font-weight: 600;
              justify-content: center;
            }
            span {
              ul {
                left: inherit;
                right: -10px;
                width: 130px;
                &:before,
                &:after {
                  left: inherit;
                  right: 10px;
                }
                li {
                  display: block;
                  a {
                    font-size: 14px;
                    color: $dark-color;
                    i {
                      margin-left: 10px;
                      font-size: 13px;
                      color: $dark-color;
                    }
                  }
                }
              }
            }
            .message {
              position: relative;
            }
          }
          .flag-icon {
            font-size: 16px;
          }
        }
        .bookmark-flip {
          width: unset !important;
          box-shadow: unset !important;
          background-color: transparent !important;
          overflow: initial;
          &::after,
          &::before {
            display: none;
          }
          .Typeahead-menu {
            top: 28%;
            max-height: 154px;
          }
          .bookmark-dropdown {
            li{
              &.list-group-item {
                &:first-child {
                  height: 120px;
                  overflow: auto;
                }
              }
            }
            .bookmark-content {
              text-align: center;
              .bookmark-icon {
                width: 52px;
                height: 52px;
                background-color: #f9f9f9;
                border-radius: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0 auto;
              }
              span {
                // font-family: $font-roboto, $font-serif;
                font-size: 12px;
                font-weight: 500;
                letter-spacing: 0.5px;
                text-overflow:ellipsis;
                overflow:hidden;
                display: -webkit-box !important;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                white-space: normal; 
                // color: $theme-font-color; 
              }
            }
          }
        }
        .flip-card {
          width: 300px;
          height: 260px;
          background-color: transparent;
          .flip-card-inner {
            position: relative;
            height: 100%;
            text-align: center;
            transition: transform 0.6s;
            transform-style: preserve-3d;
            .front,
            .back {
              position: absolute;
              backface-visibility: hidden;
              border-radius: 10px;
              overflow: hidden;
              box-shadow: 0 0 20px rgba(89, 102, 122, 0.1);
              width: 300px;
              button {
                // background-color: $transparent-color;
                // color: var(--theme-default);
                border: none;
                &:focus {
                  // outline: $transparent-color;
                }
              }
            }
            .front {
              background-color: $white;
              transition: .3s;
              svg {
                stroke: $dark-color;
                fill: none;
                transition: all 0.3s ease;
                &:hover {
                  // stroke: var(--theme-default);
                  transition: all 0.3s ease;
                }
              }
            }
            .back {
              background-color: $white;
              transform: rotateY(180deg);
              li {
                &:first-child {
                  // margin-top: 20px;
                  height: 235px !important;
                }
              }
              button {
                width: 100%;
              }
              .flip-back-content {
                input {
                  border-radius: 25px;
                  padding: 6px 12px;
                  width: 100%;
                  border: 1px solid #ddd;
                  &:focus {
                    outline: none;
                  }
                }
              }
            }
            &.flipped {
              transform: rotateY(180deg);
              .front {
                opacity: 0;
              }
            }
          }
        }
        .onhover-show-div {
          width: 330px;
          top: 50px;
          right: 0;
          left: unset;
          .dropdown-title {
            padding: 20px;
            text-align: center;
            // border-bottom: 1px solid rgba($dropdown-border-color, 0.3);
          }
          ul {
            padding: 15px;
            li {
              .d-flex {
                position: relative;
                .message-img {
                  padding: 5px;
                  border-radius: 50%;
                  img {
                    position: relative;
                    border-radius: 50%;
                    width: 40px !important;
                    height: auto;
                    margin: 0; 
                  }
                }
                .flex-grow-1 {
                  margin-left: 15px;
                  h5 {
                    a {
                      // color: $theme-font-color;
                      [class="dark-only"] & {
                        color: rgba($white, 0.6);
                      }
                    }
                  }
                  p {
                    margin-bottom: 0;
                    opacity: 0.6;
                    line-height: 1.4;
                  }
                }
                .notification-right {
                  svg {
                    vertical-align: middle;
                    width: 18px;
                    margin-top: -10px;
                    // stroke: $theme-body-sub-title-color;
                  }
                }
              }
            }
          }
          &.message-dropdown {
            li {
              &:last-child {
                padding-bottom: 0;
                text-align: center;
              }
            }
          }
          li {
            padding: 15px;
            + li {
              // border-top: 1px solid rgba($dropdown-border-color, 0.3) !important;
            }
            p {
              font-size: 14px;
              letter-spacing: 0.3px;
              margin-bottom: 0;
            }
            a {
              letter-spacing: 0.3px;
            }
            &:last-child {
              padding-bottom: 0;
            }
            .d-flex {
              position: relative;
              img {
                width: 40px !important;
                position: relative;
              }
              .status-circle {
                left: 0;
              }
              .flex-grow-1 {
                >span {
                  letter-spacing: 0.8px;
                  display: inline-block;
                }
                p {
                  margin-bottom: 8px;
                }
              }
            }
          }
          &:before,
          &:after {
            right: 10px !important;
            left: unset !important;
          }
        }
        .notification-box {
          position: relative;
        }
        .cart-box {
          position: relative;
        }
        .cart-dropdown {
          width: 270px;
          ul {
            padding: 0;
          }
          li {
            margin: 0 15px;
            padding: 10px 0;
            .d-flex {
              align-items: center;
              .flex-grow-1 {
                margin-right: 20px;
                span {
                  font-size: 16px;
                  font-weight: 500;
                  margin-bottom: 5px;
                  // color: $theme-font-color;
                }
                h6 {
                  // color: $theme-body-sub-title-color;
                  margin-bottom: 5px;
                }
              }
            }
            &+li {
              margin-top: 0;
              // border-top: 1px solid rgba($dropdown-border-color, 0.1);
            }
            &:last-child {
              padding: 10px 0;
              border-top: none;
            }
            .view-cart ,.view-checkout{
              font-size: 16px;
              font-weight: 500;
              // color: $theme-font-color;
              transition: all 0.4s;
              &:hover {
                // color: var(--theme-default);
                transition: all 0.4s;
              }
            }
          }
          p {
            line-height: 1.3;
            margin-top: 3px;
            opacity: 0.6;
          }
          .close-circle {
            position: absolute;
            right: 0;
            top: 0;
            a {
              i {
                // color: $theme-body-sub-title-color;
              }
            }
            svg {
              width: 14px;
              stroke: $white;
            }
          }
          .total {
            // border-bottom: 1px solid rgba($dropdown-border-color, 0.1);
            h5 {
              font-weight: 500;
              // color: $theme-body-sub-title-color;
              span {
                font-size: 14px;
              }
            }
          }
          .view-checkout {
            text-decoration: unset;
          }
        }
        .notification-box {
          svg {
            animation: swing 1.5s ease infinite;
          }
        }
        .notification-dropdown {
          .d-flex {
            padding: 15px 15px 15px 15px;
            // border-bottom: 1px solid $light-gray;
            &:last-child{
              border-bottom: none;
            }
            &.figma-line {
              border-bottom: none;
            }
            img {
              border-radius: 50px;
            }
            .flex-grow-1 {
              a {
                display: block;
                h5 {
                  font-size: 14px;
                  font-weight: 500;
                  // color: $theme-font-color;
                  margin-bottom: 5px;
                  strong {
                    // color: $theme-body-sub-title-color;
                    font-weight: 500;
                  }
                }
                span {
                  font-size: 13px;
                  font-weight: 400;
                  // color: $theme-body-sub-title-color;
                }
              }
            }
            .flex-shrink-0 {
              div[class*="activity-dot-"] {
                margin-top: 3px;
                animation: round 1.3s ease-in-out infinite;
              }
            }
          }
        }
        .profile-dropdown {
          width: 160px;
          top: 57px;
          left: -12px;
          padding: 0 10px;
          li {
            padding: 10px !important;
            a {
              text-decoration: unset;
            }
            &:hover {
              a {
                span {
                  // color: var(--theme-default);
                  transition: color 0.3s ease;
                }
                svg {
                  // stroke: var(--theme-default);
                  transition: stroke 0.3s ease;
                  path {
                    // stroke: var(--theme-default);
                    transition: stroke 0.3s ease;
                  }
                }
              }
            }
            +li {
              margin-top: 0;
            }
            &:last-child {
              padding-top: 10px !important;
            }
            span {
              color: $dark-color;
            }
            svg {
              width: 16px;
              vertical-align: bottom;
              margin-right: 10px;
              stroke: $dark-color;
              fill: none !important;
            }
          }
        }
      }
    }
  }
  .page-body-wrapper {
    background-color: $main-body-color;
    .page-body {
      // min-height: calc(100vh - #{$header-size});
      // margin-top: $header-size;
      // padding: $page-body-padding;
      position: relative;
      transition: 0.5s;
      &:before {
        animation: fadeIn 0.5s ease-in-out;
      }
    }
    .page-title {
      // padding: $page-title-padding;
      margin: 0 -27px 0;
      >.row {
        margin: 0;
        .col-6 {
          padding: 0;
        }
      }
      .row {
        align-items: center;
        h3 {
          // text-transform: $page-title-text-tranform;
          // color: $theme-font-color;
          font-weight: 500;
          font-size: 22px;
          small {
            // display: $page-small-title-display;
            // font-size: $page-small-title-font-size;
            // margin-bottom: $page-small-title-margin-bottom;
            // text-transform: $page-small-title-text-transform;
            // color: $theme-body-sub-title-color;
          }
        }
      }
      .breadcrumb {
        // background-color: $transparent-color;
        padding: 0;
        margin-bottom: 0;
        align-items: center;
        justify-content: flex-end;
        li {
          &:first-child {
            position: relative;
            display: flex;
            height: 30px;
            width: 30px;
            align-items: center;
            justify-content: center;
            background-color: rgba($primary-color, 0.1);
          }
        }
        .breadcrumb-item {
          // color: var(--theme-default);
          font-weight: 500;
          &+.breadcrumb-item {
            &:before {
              // content: $breadcrumb-content;
              // color: var(--theme-default);
            }
          }
          a {
            svg {
              width: 16px;
              height: 16px;
              stroke: $primary-color;
              vertical-align: middle;
            }
          }
          &.active {
            color: rgba($primary-color, 0.6);
          }
        }
      }
    }
    div.sidebar-wrapper {
      .sidebar-main {
        .sidebar-links {
          .simplebar-wrapper {
            .simplebar-mask {
              .simplebar-content-wrapper {
                .simplebar-content {
                  >li {
                    a {
                      svg {
                        &.stroke-icon {
                          width: 19px;
                          height: 19px;
                          // stroke: $sidebar-fill-icon-color;
                        }
                        &.fill-icon {
                          width: 19px;
                          height: 19px;
                          // fill: $sidebar-fill-icon-color;
                        }
                      }
                    }
                  }
                  li {
                    a {
                      display: block;
                      color: #363636;
                      line-height: 23px;
                      text-decoration: none;
                      text-transform: capitalize;
                      padding: 5px 15px;
                      margin: 0 3px;
                      letter-spacing: 0.04em;
                      position: relative;
                      border-radius: 5px;
                      font-weight: 500;
                      -webkit-transition: all 0.3s ease;
                      transition: all 0.3s ease;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .sidebar-wrapper {
    &[data-layout="stroke-svg"] {
      .stroke-icon {
        display: inline-block;
      }
      .fill-icon {
        display: none;
      }
    }
    &[data-layout="fill-svg"] {
      .stroke-icon {
        display: none;
      }
      .fill-icon {
        display: inline-block;
      }
    }
  }
  &.horizontal-wrapper {
    [data-simplebar] {
      position: relative;
    }
    .footer {
      margin-left: 0;
    }
    .header-logo-wrapper {
      width: 150px;
      .toggle-sidebar {
        display: none;
      }
    }
    .sidebar-list {
      i.fa-thumb-tack {
        visibility: hidden;
        position: absolute;
        top: 19px;
        right: 0px;
        // color: var(--theme-default);
        [dir="rtl"] & {
          left: 0;
          right: unset;
        }
      }
      &:hover {
        i.fa-thumb-tack {
          visibility: hidden;
          cursor: pointer;
        }
      }
      .sidebar-link {
        &:hover {
          background-color: rgba($primary-color, 0.1);
        }
      }
    }
    .page-body-wrapper {
      .sidebar-wrapper {
        height: 54px;
        line-height: inherit;
        top: 76px;
        // background: $sidebar-horizontal-background;
        box-shadow: 0px 9px 24px rgba($primary-color, 0.05);
        overflow-x: hidden;
        z-index: 1;
        overflow-y: visible;
        position: fixed;
        width: 100vw;
        // border-top: 1px solid $light-semi-gray;
        z-index: 3;
        .simplebar-content {
          display: flex;
        }
        li {
          display: inline-block;
        }
        .badge {
          position: absolute;
          top: 2px;
          right: 0px;
          z-index: 1;
        }
        .logo-wrapper,
        .logo-icon-wrapper {
          display: none;
        }
        .sidebar-main {
          text-align: left;
          position: relative;
          .left-arrow,
          .right-arrow {
            position: absolute;
            top: 0px;
            padding: 15px;
            // background-color: $sidebar-horizontal-background;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 1;
            cursor: pointer;
            &.disabled {
              display: none;
            }
          }
          .left-arrow {
            left: 0;
          }
          .right-arrow {
            right: 0;
          }
          .sidebar-links {
            list-style: none;
            margin: 0;
            padding: 0;
            line-height: normal;
            direction: ltr;
            text-align: left;
            display: -webkit-box !important;
            padding: 0 5px;
            transition: all .5s ease;
            white-space: nowrap;
            li {
              &.sidebar-list {
                a {
                  &.active {
                    .according-menu {
                      i {
                        &:before {
                          content: '-';
                        }
                      }
                    }
                  }
                  .according-menu {
                    display: none;
                  }
                }
                ul.sidebar-submenu {
                  li {
                    a {
                      &.active {
                        // color: var(--theme-default);
                        &:after {
                          // background-color: var(--theme-default);
                        }
                      }
                    }
                  }
                }
              }
            }
            .simplebar-wrapper {
              .simplebar-mask {
                .simplebar-content-wrapper {
                  .simplebar-content {
                    >li {
                      position: relative;
                      padding: 10px 0 !important;
                      background-color: transparent;
                      &:hover {
                        >a {
                          &:after {
                            left: 0;
                            right: 0;
                            opacity: 1;
                          }
                        }
                      }
                      &.sidebar-main-title {
                        &.pin-title {
                          display: none !important;
                          &.show {
                            display: none !important;
                          }
                        }
                      }
                      a {
                        svg {
                          &.stroke-icon {
                            // stroke: $sidebar-fill-icon-color;
                            vertical-align: sub;
                            margin-right: 5px;
                          }
                          &.fill-icon {
                            // fill: $sidebar-fill-icon-color;
                            vertical-align: sub;
                            margin-right: 5px;
                          }
                        }
                      }
                      &:first-child {
                        display: none !important;
                      }
                      >a {
                        display: block;
                        // color: $theme-body-font-color;
                        line-height: 23px;
                        text-decoration: none;
                        text-transform: capitalize;
                        padding: 5px 15px;
                        margin: 0 3px;
                        letter-spacing: 0.04em;
                        position: relative;
                        border-radius: 5px;
                        font-weight: 500;
                        transition: all 0.3s ease;
                        &:hover {
                          background-color: rgba($primary-color, 0.2);
                          transition: all 0.3s ease;
                        }
                        &.active {
                          background-color: rgba($primary-color, 0.2);
                          transition: all 0.3s ease; 
                          svg,
                          span {
                            // color: var(--theme-default);
                            transition: all 0.3s ease;
                          }
                        }
                        .badge {
                          position: absolute;
                          top: -5px;
                          margin-left: 1px;
                          right: 10px;
                          padding: 0.20em 0.7em;
                        }
                        svg {
                          width: 18px;
                          height: 18px;
                          vertical-align: sub;
                          margin-right: 10px;
                        }
                      }
                      .sidebar-submenu {
                        position: fixed;
                        z-index: 4;
                        // box-shadow: 0 0 1px 0 $dark-gray;
                        padding: 15px 0px 15px 0px;
                        background: white;
                        li {
                          border: 0;
                          float: none;
                          position: relative;
                          display: block;
                          a {
                            background: transparent;
                            padding: 4px 35px;
                            display: block;
                            transition: all 0.5s ease;
                            line-height: 1.9;
                            // color: $theme-body-font-color;
                            letter-spacing: 0.06em;
                            font-weight: 500;
                            transition: all 0.3s ease;
                            &:after {
                              left: 20px;
                              @extend %horizontal-after;
                            }
                            &:hover {
                              // color: var(--theme-default);
                              letter-spacing: 1.5px;
                              transition: all 0.3s ease;
                              &:after {
                                // background-color: var(--theme-default);
                              }
                            }
                          }
                        }
                      }

                     

                      .mega-menu-container {
                        position: fixed;
                        right: 0;
                        left: 0;
                        margin: 0 auto;
                        z-index: 4;
                        // box-shadow: 0 0 1px 0 $dark-gray;
                        padding: 30px;
                        background: white;
                        width: 95%;
                        border-radius: 4px;
                        display: block !important;
                        .mega-box {
                          width: 20%;
                          .link-section {
                            .submenu-title {
                              h5 {
                                text-transform: uppercase;
                                font-size: 14px;
                                margin-left: -13px;
                              }
                            }
                            .submenu-content {
                              box-shadow: none;
                              background-color: transparent;
                              position: relative;
                              display: block;
                              padding: 0;
                              li {
                                border: 0;
                                float: none;
                                position: relative;
                                display: block;
                                a {
                                  border: 0 !important;
                                  background: transparent;
                                  // color: $theme-body-font-color;
                                  border-radius: 0 !important;
                                  text-transform: capitalize;
                                  padding: 3px 0;
                                  font-size: 13px;
                                  display: block;
                                  letter-spacing: 0.07em;
                                  line-height: 1.9;
                                  position: relative;
                                  transition: all 0.3s ease;
                                  &:hover {
                                    // color: var(--theme-default);
                                    letter-spacing: 1.5px;
                                    transition: all 0.3s ease;
                                    &:after {
                                      // color: var(--theme-default);
                                      transition: all 0.3s ease;
                                    }
                                  }
                                  &:after {
                                    position: absolute;
                                    content: "";
                                    left: -12px;
                                    top: 50%;
                                    width: 5px;
                                    height: 1px;
                                    // background-color: $light-semi-font;
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                      &.mega-menu {
                        position: unset;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        &.overlay-white {
          background-color: rgba(255, 255, 255, 0.08);
        }
      }
      .toggle-nav {
        position: relative;
        display: flex;
        align-items: center;
        cursor: pointer;
        i {
          font-size: 24px;
        }
      }
      .page-body {
        margin-top: 130px;
      }
      .sidebar-main-title {
        display: none !important;
      }
      &.scorlled {
        .sidebar-wrapper {
          display: none;
          transition: all 0.3s ease;
        }
      }
    }
    .page-header {
      width: 100%;
      box-shadow: none;
      .header-wrapper {
        .form-control-plaintext {
          width: auto !important;
        }
        .left-header {
          width: 30%;
          @media (max-width: 1428px) and (min-width: 1324px) {
            width: 32%;
          }
          @media (max-width: 1323px) and (min-width: 1200px) {
            width: 37%;
          }
          @media (max-width: 1199px) and (min-width: 992px) {
            width: 32%;
          }
        }
        .nav-right {
          @media (max-width: 1428px) and (min-width: 1324px) {
            width: 55%;
          }
          @media (max-width: 1323px) and (min-width: 1200px) {
            width: 40%;
          }
          @media (max-width: 1199px) and (min-width: 992px) {
            width: 50%;
          }
        }
      }
    }
    &.enterprice-type {
      footer {
        margin-left: 0;
      }
    }
  }
  &.material-icon {
    .card {
      border-radius: 30px;
      box-shadow: 0 0 20px rgba($primary-color, 0.10);
    }
  }
  .sidebar-main-title {
    h6 {
      font-size: 14px;
      font-weight: 600;
      color: $white;
      margin-bottom: 0;
      letter-spacing: 0.40px;
      // background-color: $dark-body-background;
      padding: 8px 16px;
      width: fit-content;
    }
  }
  // left sidebar css
  &.compact-wrapper {
    .sidebar-list {
      i.fa-thumb-tack {
        visibility: hidden;
        position: absolute;
        top: 11px;
        right: -9px;
        // color: var(--theme-default);
        z-index: 9999;
        [dir="rtl"] & {
          left: -9px;
          right: unset;
        }
      }
      &:hover {
        i.fa-thumb-tack {
          visibility: visible;
          cursor: pointer;
        }
      }
      &.pined {
        order: -1;
      }
    }
    .pin-title {
      order: -1;
    }
    .page-header {
      // margin-left: $sidebar-compact-width;
      // width: calc(100% - $sidebar-compact-width);
      &.close_icon {
        margin-left: 71px;
        width: calc(100% - 71px);
      }
      .header-wrapper {
        .logo-wrapper {
          display: none;
        }
        .toggle-sidebar {
          display: none;
        }
      }
    }
    .page-body-wrapper {
      div {
        &.sidebar-wrapper {
          position: fixed;
          top: 0;
          z-index: 9;
          height: auto;
          line-height: inherit;
          // background: $sidebar-background;
          // width: $sidebar-width;
          text-align: left;
          transition: 0.3s;
          // box-shadow: 0 0 21px 0 rgba($semi-dark, 0.1);
          .logo-wrapper,
          .logo-icon-wrapper {
            padding: 20px;
            // border-bottom: 1px solid $dark-card-border;
          }
          .logo-icon-wrapper {
            display: none;
          }
          &.close_icon {
            width: 72px;
            transition: all 0.3s ease;
            
            .badge {
              opacity: 0;
              width: 0;
              padding: 0;
            }
            .sidebar-main {
              .sidebar-links {
                .simplebar-wrapper {
                  .simplebar-mask {
                    .simplebar-content-wrapper {
                      .simplebar-content {
                        padding: 0 10px !important;
                        >li {
                          max-width: 86px;
                          margin: 0;
                          transition: all 0.3s ease;
                          background-color: unset;
                          .sidebar-submenu {
                            &::before {
                              border: unset;
                            }
                            a {
                              display: none;
                            }
                          }
                          a {
                            text-align: center;
                            padding: 8px 0px !important;
                            justify-content: center;
                            transition: all 0.3s ease;
                            margin: 0;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            .sidebar-main-title {
              display: none !important;
              opacity: 0;
              transition: all 0.3s ease;
            }
            .logo-wrapper {
              display: none;
            }
            .logo-icon-wrapper {
              text-align: right;
              display: block;
              padding: 23px 20px;
            }
            &:hover {
              // width: $sidebar-compact-width;
              transition: all 0.3s ease;
              .badge {
                opacity: 1;
                width: auto;
                transition: all 0.3s ease;
              }
              .sidebar-main {
                .sidebar-links {
                  .simplebar-wrapper {
                    .simplebar-mask {
                      .simplebar-content-wrapper {
                        .simplebar-content {
                          >li {
                            max-width: unset;
                            margin: initial;
                            transition: all 0.3s ease;
                            a {
                              text-align: left;
                              [dir="rtl"] & {
                                text-align: right;
                              }
                            }
                            .sidebar-submenu {
                              a {
                                display: block;
                              }
                            }
                            >a {
                              padding: 12px 15px;
                              transition: all 0.3s ease;
                              justify-content: start;
                              span {
                                display: inline-block;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              .sidebar-main-title {
                opacity: 1;
                display: block !important;
                transition: all 0.3s ease;
              }
              .sidebar-main {
                .sidebar-links {
                  .simplebar-wrapper {
                    .simplebar-mask {
                      .simplebar-content-wrapper {
                        .simplebar-content {
                          padding: 0 23px !important;
                          >li {
                            transition: all 0.3s ease;
                            .sidebar-submenu {
                              a {
                                display: block;
                              }
                            }
                            >a {
                              transition: all 0.3s ease;
                              span {
                                display: inline-block;
                              }
                            }
                            a {
                              span {
                                display: inline-block;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              .logo-wrapper {
                display: block;
              }
              .logo-icon-wrapper {
                display: none;
              }
            }
            .mobile-sidebar {
              transform: translate(183px);
            }
            ~.page-body,
            ~footer {
              margin-left: 71px;
              transition: 0.5s;
            }
            ~.footer-fix {
              width: calc(100% - 90px);
            }
            .sidebar-main {
              .sidebar-links {
                height: calc(100vh - 118px);
                .simplebar-wrapper {
                  .simplebar-mask {
                    .simplebar-content-wrapper {
                      .simplebar-content {
                        >li {
                          .sidebar-link {
                            &::before {
                              right: 20px;
                              left: unset;
                            }
                          }
                          a {
                            span {
                              display: none;
                            }
                          }
                        }
                      }
                    }
                  }
                }
                .sidebar-list {
                  .sidebar-title {
                    &.active {
                      ~.sidebar-submenu {
                        display: none;
                      }
                    }
                  }
                }
              }
            }
            .according-menu {
              display: none;
            }
          }
          .sidebar-main {
            .left-arrow,
            .right-arrow {
              display: none;
            }
            .sidebar-list {
              position: relative;
              i.fa-thumb-tack {
                visibility: hidden;
                position: absolute;
                top: 14px;
                right: 20px;
                // color: var(--theme-default);
                [dir="rtl"] & {
                  left: 35px;
                  right: unset;
                }
              }
              &:hover {
                i.fa-thumb-tack {
                  visibility: visible;
                  cursor: pointer;
                }
              }
              &.pined,
              &.pin-title {
                order: -1;
              }
              &.pined {
                .fa-thumb-tack {
                  transform: rotate(45deg);
                }
              }
            }
            .pin-title {
              order: -1;
            }
            .sidebar-links {
              // height: $sidebar-height;
              left: -300px;
              z-index: 99;
              transition: color 1s ease;
              overflow: auto;
              color: rgba(0, 0, 0, 0);
              margin-top: 20px;
              margin-bottom: 30px;
              li {
                a {
                  font-weight: 500;
                  svg {
                    width: 21px;
                    height: 21px;
                    vertical-align: -2px;
                    float: none;
                    transition: all 0.3s ease;
                  }
                  span {
                    letter-spacing: 0.7px;
                    text-transform: capitalize;
                    // color: $sidebar-fill-icon-color;
                    padding: 4px 8px;
                  }

                  &.link-nav {
                    .according-menu {
                      display: none;
                    }
                  }
                }
                &:last-child {
                  border-bottom: none;
                }
              }
              &.opennav {
                left: 0;
              }
              .back-btn {
                display: block;
                width: 100%;
                padding: 0;
                .mobile-back {
                  padding: 20px;
                  // color: $theme-font-color;
                  font-weight: 700;
                  text-transform: uppercase;
                  border-bottom: 1px solid #efefef;
                  cursor: pointer;
                  display: flex;
                  justify-content: flex-end;
                  align-items: center;
                }
              }
              .simplebar-wrapper {
                .simplebar-mask {
                  .simplebar-content-wrapper {
                    .simplebar-content {
                      >li {
                        display: block;
                        width: 100%;
                        position: relative;
                        background-color: unset !important;
          
                        &.sidebar-main-title {
                          padding: 16px 0 10px 0px;
                          border: none;
                          &.pin-title {
                            display: none !important;
                            &.show {
                              display: block !important;
                            }
                          }
                          &::before {
                            position: absolute;
                            content: '';
                            bottom: 0;
                            left: 50%;
                            width: calc(100% - 40px);
                            height: 1px;
                            transform: translateX(-50%);
                          }
                          &:nth-child(2) {
                            margin-top: 0;
                            &::before {
                              display: none;
                            }
                          }
                        }
                        &.sidebar-list {
                          .badge {
                            position: absolute;
                            right: 50px;
                            top: 14.5px;
                            padding: 0.45em 0.8em 0.33em;
                            z-index: 1;
                            font-size: 10px;
                            letter-spacing: 0.7px;
                            font-weight: 600;
                            // font-family: $font-roboto, $font-serif;
                            border-radius: 10px;
                          }
                        }
                        a {
                          padding: 6px 0 !important;
                          position: relative;
                          color: #222222;
                          transition: all 0.3s ease;
                          .sub-arrow {
                            right: 20px;
                            position: absolute;
                            top: 10px;
                            i {
                              display: none;
                            }
                            &:before {
                              display: none;
                            }
                          }
                        }
                        &:first-child {
                          display: none !important;
                        }
                        .sidebar-submenu {
                          width: 100%;
                          padding: 0;
                          position: relative;
                          &::before {
                            @extend %sidebar-before;
                          }
                          >li {
                            a {
                              padding-left: 30px !important;
                              font-weight: 400;
                            }
                          }
                          .submenu-title {
                            .according-menu {
                              top: 7px;
                            }
                          }
                          &.opensubmenu {
                            display: block;
                          }
                          li {
                            
                            &:hover {
                              >a {
                                svg {
                                  transition: all 0.3s ease;
                                  position: relative;
                                  content: '';
                                  width: 35px;
                                  height: 35px;
                                  padding: 8px;
                                  top: 0;
                                  -webkit-transition: 0.8s;
                                  transition: 0.8s;
                                  border-radius: 50px;
                                }
                              }
                            }
                            .opensubmegamenu {
                              display: none;
                              position: relative !important;
                              right: 0;
                              width: 100%;
                              padding: 0;
                              &.opensubchild {
                                display: block;
                              }
                              li {

                                a {
                                  padding: 6px 45px;
                                  &:after {
                                    display: none;
                                  }
                                }
                              }
                            }
                            a {
                              padding: 7px 25px;
                              font-size: 14px;
                              // color: $sidebar-dashed-border;;
                              display: block;
                              position: relative;
                              letter-spacing: 0.06em;
                              font-weight: 500;
                              transition: all 0.3s ease;
                              white-space: nowrap;
                              overflow: hidden;
                              text-overflow: ellipsis;
                              &.active {
                                span{
                                  color: $white;
                                }
                              }
                              span{
                                padding: 0px;
                              }
                              &:after {
                                left: 12px;
                                @extend %sidebar-after;
                              }
                              &:hover {
                                margin-left: 0;
                                [dir="rtl"] & {
                                  margin-left: unset;
                                  margin-right: 0;
                                }
                              }
                            }
                          }
                        }
                        .mega-menu-container {
                          padding: 0;
                          position: relative;
                          &::before {
                            @extend %sidebar-before;
                          }
                          &.opensubmenu {
                            display: block;
                          }
                          .mega-box {
                            width: 100%;
                            padding: 0;
                            .link-section {
                              .submenu-title {
                                position: relative;
                                h5 {
                                  margin-bottom: 0;
                                  line-height: 1.9;
                                  padding: 8px 0px 5px 30px;
                                  font-size: 14px;
                                  position: relative;
                                  // color: $sidebar-fill-icon-color;
                                  display: block;
                                  letter-spacing: 0.06em;
                                  font-weight: 500;
                                  cursor: pointer;
                                  &:after {
                                    left: 12px;
                                    @extend %sidebar-after;
                                    [dir="rtl"] & {
                                      left: unset;
                                      right: 12px;
                                    }
                                  }
                                  [dir="rtl"] & {
                                    padding: 8px 30px 5px 0px;
                                  }
                                }
                                .according-menu {
                                  top: 8px;
                                }
                              }
                              .submenu-content {
                                &.opensubmegamenu {
                                  li {
                                    a {
                                      margin-bottom: 0;
                                      line-height: 1.9;
                                      padding: 6px 8px 6px 30px !important;
                                      font-size: 14px;
                                      position: relative;
                                      // color: $sidebar-fill-icon-color;
                                      display: block;
                                      letter-spacing: 0.06em;
                                      font-weight: 500;
                                      &:after {
                                        display: none;
                                      }
                                      &.active {
                                        color: $white;
                                      }
                                      [dir="rtl"] & {
                                        padding: 6px 30px 6px 8px !important;
                                      }
                                    }
                                  }
                                }
                                ul {
                                  li {
                                    a {
                                      line-height: 1.9;
                                      &:hover {
                                        margin-left: 0;
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                        &.mega-menu {
                          .sidebar-title {
                            &.active {
                              ~.mega-menu-container {
                                display: block !important;
                              }
                            }
                          }
                        }
                        .sidebar-link {
                          transition: all 0.5s ease;
                          display: block;
                          display: flex;
                          align-items: center;
                          justify-content: start;
                          &.active {
                            transition: all 0.5s ease;
                            position: relative;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          .simplebar-content {
            display: flex;
            flex-direction: column;
            padding: 0 23px !important;
          }
        }
      }
      .mega-box {
        &.col {
          flex-basis: unset;
        }
      }
      .according-menu {
        position: absolute;
        right: 0px;
        top: 8px;
        display: block;
        font-size: 18px;
        i {
          margin-left: 0px;
          vertical-align: middle;
          // color: $sidebar-fill-icon-color;
          font-weight: 500; 
        }
      }
      .main-menu {
        &.border-section {
          border: none;
        }
        .menu-left {
          .main-menu-right {
            .toggle-nav {
              position: absolute;
              z-index: 2;
              right: 145px;
              top: 6px;
              .sidebar-name {
                font-size: 18px;
                padding-left: 5px;
                text-transform: uppercase;
              }
            }
          }
        }
      }
      .page-body {
        min-height: calc(100vh - 80px);
        margin-top: 75px;
        // margin-left: $sidebar-compact-width;
      }
    }
    &.dark-sidebar {
      .sidebar-main-title {
        h6 {
          // color: $dark-all-font-color;
        }
      }
      .toggle-sidebar {
        svg {
          // stroke: var(--theme-default);
        }
      }
      .page-body-wrapper {
        div.sidebar-wrapper {
          .sidebar-main {
            .sidebar-links {
              .simplebar-wrapper {
                .simplebar-mask {
                  .simplebar-content-wrapper {
                    .simplebar-content {
                      >li {
                        a {
                          svg {
                            &.stroke-icon {
                              // stroke: $dark-all-font-color;
                            }
                            &.fill-icon {
                              // fill: $dark-all-font-color;
                            }
                          }
                          &.active {
                            svg {
                              &.stroke-icon {
                                // stroke: var(--theme-default);
                              }
                              &.fill-icon {
                                // fill: var(--theme-default);
                              }
                            }
                          }
                        }
                        &.sidebar-main-title {
                          &::before {
                            // background: $dark-card-border;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      div.sidebar-wrapper {
        // background-color: $dark-card-background;
        .sidebar-main {
          .sidebar-links {
            li {
              a {
                span {
                  // color: $dark-all-font-color;
                }
                svg {
                  &.stroke-icon {
                    // stroke: $dark-all-font-color;
                  }
                  &.fill-icon {
                    // fill: $dark-all-font-color;
                  }
                }
              }
            }
            .simplebar-wrapper {
              .simplebar-mask {
                .simplebar-content-wrapper {
                  .simplebar-content {
                    >li {
                      .mega-menu-container {
                        .mega-box {
                          .link-section {
                            .submenu-title {
                              h5 {
                                // color: $dark-all-font-color;
                              }
                            }
                            .submenu-content {
                              &.opensubmegamenu {
                                li {
                                  a {
                                    // color: $dark-all-font-color;
                                    font-weight: 400;
                                  }
                                }
                              }
                            }
                          }
                        }
                        &::after {
                          // background-color: $light-all-font-color;
                        }
                      }
                      .sidebar-link {
                        &.active {
                          background-color: rgba($primary-color, 0.2);
                          svg {
                            // color: var(--theme-default);
                            // stroke: var(--theme-default);
                          }
                        }
                      }
                      .sidebar-submenu {
                        li {
                          a {
                            // color: $dark-all-font-color;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            .sidebar-list {
              ul.sidebar-submenu {
                li {
                  a {
                    span {
                      // color: $dark-all-font-color;
                    }
                    &.active {
                      // color: var(--theme-default);
                    }
                  }
                }
              }
            }
          }
        }
        .logo-wrapper {
          .for-light {
            display: none;
          }
          .for-dark {
            display: block;
          }
        }
      }
      .according-menu {
        i {
          // color: $dark-all-font-color;
        }
      }
    }
  }
  // compact icon css
  &.compact-sidebar {
    .simplebar-wrapper {
      .simplebar-mask {
        .simplebar-content-wrapper {
          .simplebar-content {
            display: flex;
            flex-direction: column;
          }
        }
      }
    }
    .sidebar-list {
      i.fa-thumb-tack {
        visibility: hidden;
        position: absolute;
        top: 8px;
        right: 54px;
        // color: var(--theme-default);
        width: 20px;
        height: 20px;
        // background-color: $light-primary;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        [dir="rtl"] & {
          left: 54px;
          right: unset;
        }
      }
      &:hover {
        i.fa-thumb-tack {
          visibility: visible;
          cursor: pointer;
          z-index: 1;
        }
      }
      &.pined {
        order: -1;
      }
    }
    .pin-title {
      order: -1;
    }
    ~.bg-overlay1 {
      transition: 0.8s;
      &.active {
        z-index: 8;
        height: 100vh;
        width: 100vw;
        background-color: rgba($black, 0.2);
        position: fixed;
        top: 0;
      }
    }
    .toggle-sidebar {
      position: relative;
      right: unset;
      top: unset;
      margin-right: 15px;
    }
    .sidebar-main-title {
      display: none;
    }
    .logo-wrapper {
      display: none;
    }
    .logo-icon-wrapper {
      display: block;
      text-align: center;
      padding: 24px 30px;
      box-shadow: -9px 0 20px rgba(89, 102, 122, 0.1);
    }
    .page-header {
      margin-left: 150px;
      width: calc(100% - 150px);
      .header-wrapper {
        .logo-wrapper {
          display: none;
        }
        .left-header {
          width: 30%;
          @media (max-width: 1578px) and (min-width:1274px) {
            width: 38%;
          }
          @media (max-width: 1273px) and (min-width:1200px) {
            width: 45%;
          }
          @media (max-width:991px) and (min-width: 768px) {
            width: 36%;
          }
          @media (max-width:767px) and (min-width:756px) {
            width: 28%;
          }
          h6 {
            @media (max-width: 1199px) {
              width: 132px;
            }
            @media (max-width:767px) {
              width: 83px;
            }
          }
        }
      }
      &.close_icon {
        margin-left: 0;
        width: calc(100% - 0px);
      }
    }
    .page-body-wrapper {
      .page-body {
        margin-top: 80px;
        margin-left: 150px;
      }
      .footer {
        margin-left: 150px;
      }
      div.sidebar-wrapper {
        position: fixed;
        top: 0;
        z-index: 9;
        >div {
          height: auto;
          line-height: inherit;
          background: $white;
          width: 150px;
          text-align: left;
          transition: 0.3s;
          box-shadow: 0 0 21px 0 rgba(89, 102, 122, 0.1);
        }
        .sidebar-main {
          .left-arrow,
          .right-arrow {
            display: none;
          }
          .sidebar-links {
            margin: 30px 0;
            height: calc(100vh - 113px);
            overflow: auto;
            li {
              a {
                color: $dark-color;
                span {
                  text-transform: capitalize;
                  color: $dark-color;
                  font-weight: 500;
                  letter-spacing: 1px;
                  font-size: 14px;
                }
                &.active {
                  background: rgba($primary-color, 0.1);
                  // color: var(--theme-default);
                  border-radius: 20px;
                  margin: 0 10px;
                  span {
                    // color: var(--theme-default);
                  }
                  ~.sidebar-submenu,
                  ~.mega-menu-container {
                    animation: fadeInLeft 300ms ease-in-out;
                  }
                }
              }
              .sidebar-title {
                display: block;
                padding: 20px 0;
                border-bottom: 1px solid #f3f3f3;
              }
              .sidebar-submenu,
              .mega-menu-container {
                position: fixed;
                top: 0px;
                height: calc(100vh - 0px);
                overflow: auto;
                left: 150px;
                width: 230px;
                background-color: $white;
                box-shadow: 0 16px 15px 15px rgba(126, 55, 216, 0.03);
                transition: all 0.5s ease-in-out;
                display: none;
                padding-top: 20px;
                z-index: -1;
                li {
                  a {
                    padding: 15px 15px 15px 40px;
                    display: block;
                    position: relative;
                    border-bottom: 1px solid #f1f1f1;
                    .sub-arrow {
                      display: none;
                    }
                    .according-menu {
                      position: absolute;
                      right: 20px;
                      top: 16px;
                    }
                    &.active {
                      background-color: rgba($primary-color, 0.1);
                      // color: var(--theme-default);
                      transition: all 0.3s ease;
                    }
                  }
                  .submenu-content {
                    li {
                      a {
                        &.active {
                          // background-color: $transparent-color;
                        }
                      }
                    }
                  }
                }
                >li {
                  >a {
                    &::after {
                      position: absolute;
                      content: "";
                      left: 20px;
                      top: 48%;
                      width: 8px;
                      // border-top: 2px solid rgba($theme-body-sub-title-color, 0.4);
                    }
                  }
                }
                .mega-box {
                  flex: 0 0 100%;
                  max-width: 100%;
                  padding: 0;
                  .link-section {
                    text-align: left;
                    .submenu-title {
                      h5 {
                        position: relative;
                        font-size: 14px;
                        font-weight: 400;
                        padding: 15px 15px 15px 40px;
                        letter-spacing: 1px;
                        margin-bottom: 0;
                        &::after {
                          position: absolute;
                          content: "";
                          left: 20px;
                          top: 48%;
                          width: 6px;
                          border-top: 2px solid #595c60;
                        }
                      }
                    }
                  }
                }
              }
            }
            .sidebar-list {
              position: relative;
              .badge {
                position: absolute;
                left: 53%;
                top: 7px;
                font-weight: 500;
                font-size: 9px;
              }
              .sidebar-link {
                text-align: center;
                svg {
                  margin: 0 auto 6px;
                  width: 24px;
                  height: 24px;
                }
                >span {
                  display: block;
                  font-weight: 500;
                  letter-spacing: 1px;
                  font-size: 14px;
                  word-break: break-word;
                }
                .according-menu {
                  display: none;
                }
              }
            }
            .mega-menu {
              text-align: center;
              padding: 0;
              svg {
                width: 24px;
                height: 24px;
              }
              .according-menu {
                position: absolute;
                right: 20px;
                top: 13px;
              }
            }
          }
        }
        &[data-layout="stroke-svg"] {
          .sidebar-links {
            .mega-menu {
              svg {
                &.stroke-icon {
                  display: block;
                  margin: 0 auto 6px;
                }
                &.fill-icon {
                  display: none;
                }
              }
            }
          }
        }
        &[data-layout="fill-svg"] {
          .sidebar-links {
            .mega-menu {
              svg {
                &.stroke-icon {
                  display: none;
                }
                &.fill-icon {
                  display: block;
                  margin: 0 auto 6px;
                }
              }
            }
          }
        }
        &.close_icon {
          transform: translateX(-150px);
          ~.page-body,
          ~.footer {
            margin-left: 0px;
          }
        }
      }
    }
  }
  // compact small css
  &.compact-small {
    .toggle-sidebar {
      position: relative;
      right: unset;
      top: unset;
      margin-right: 15px;
    }
    .sidebar-list {
      i.fa-thumb-tack {
        visibility: hidden;
        position: absolute;
        top: 8px;
        right: 22px;
        // color: var(--theme-default);
        width: 20px;
        height: 20px;
        // background-color: $light-primary;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        [dir="rtl"] & {
          left: 22px;
          right: unset;
        }
      }
      &:hover {
        i.fa-thumb-tack {
          visibility: visible;
        }
      }
      &.pined,
      &.pin-title {
        order: -1;
      }
    }
    .sidebar-main-title,
    .logo-wrapper {
      display: none;
    }
    .logo-icon-wrapper {
      display: block;
      text-align: center;
      padding: 27px 30px;
      box-shadow: -9px 0 20px rgba(89, 102, 122, 0.1);
    }
    .page-header {
      margin-left: 90px;
      width: calc(100% - 90px);
      .header-wrapper {
        .logo-wrapper {
          display: none;
        }
      }
      &.close_icon {
        margin-left: 0;
        width: calc(100% - 0px);
      }
    }
    .page-body-wrapper {
      .page-body {
        margin-left: 90px;
      }
      .footer {
        margin-left: 90px;
      }
      div.sidebar-wrapper {
        >div {
          width: 90px;
        }
        .sidebar-main {
          .sidebar-links {
            @media (max-width:420px) {
              height: calc(100vh - 105px);
            }
            li {
              .sidebar-title {
                display: block;
                padding: 15px 0;
              }
              .sidebar-submenu,
              .mega-menu-container {
                left: 90px;
                li {
                  a {
                    padding: 10px 15px 10px 40px;
                    .sub-arrow {
                      display: none;
                    }
                    .according-menu {
                      top: 11px;
                    }
                  }
                }
              }
            }
            .sidebar-list {
              .badge {
                display: none;
              }
              .sidebar-link {
                svg {
                  margin: 0 auto;
                  width: 20px;
                  height: 20px;
                }
                >span {
                  display: none;
                }
              }
            }
            .mega-menu {
              svg {
                margin: 0 auto;
              }
              a {
                >span {
                  display: none;
                }
              }
            }
          }
        }
        &.close_icon {
          transform: translateX(-150px);
          ~.page-body,
          ~.footer {
            margin-left: 0px;
          }
        }
      }
    }
  }
  // only body css
  &.only-body {
    background-color: #f2f0fa;
    .page-body-wrapper {
      background-color: #f2f0fa;
    }
    .page-header {
      position: relative;
      .header-logo-wrapper {
        display: none;
      }
      .header-wrapper {
        padding: 0 !important;
        .left-header {
          display: none;
        }
        .nav-right {
          position: absolute;
          right: 60px;
          top: 58px;
        }
      }
    }
    .page-body-wrapper {
      .page-title {
        padding-top: 20px;
        padding-bottom: 20px;
      }
    }
    .sidebar-wrapper,
    footer {
      display: none;
    }
    .page-body-wrapper {
      .page-body {
        margin-top: 0px;
        min-height: calc(100vh - 0px);
      }
      .page-title {
        padding-left: 30px;
        padding-right: 30px;
        margin: 30px 0;
        border-radius: 15px;
        @media (max-width: 991.98px) {
          padding-left: 20px;
          padding-right: 20px;
          margin: 20px 0;
        }
        >.row {
          .col-6 {
            flex: 0 0 100%;
            max-width: 100%;
          }
        }
        .breadcrumb {
          margin-top: 5px;
          justify-content: flex-start;
        }
      }
    }
  }
  .left-header {
    // background-color: $light-color;
    padding: 12px !important;
    .left-menu-header {
      ul {
        &.header-left {
          li {
            .Typeahead--twitterUsers{
              input{
                border: none;
                &:focus , 
                &:focus-visible{
                  padding: 0px !important;
                  outline: none !important;
                  border: unset !important;
                  box-shadow: unset !important;
                }
              }
            }
            svg {
              vertical-align: middle;
              width: 24px;
              height: 24px;
              stroke: none;
              // fill: $theme-body-sub-title-color;
            }
          }
        } 
      }
    }
  }
  // modern css
  &.modern-type {
    .progress-chart-wrap {
      margin-left: -22px;
      [dir="rtl"] & {
        margin-left: unset;
        margin-right: -22px;
      }
    }
    .ecommerce-widget {
      border: 1px solid lighten($primary-color, 22%) !important;
    }
    .file-sidebar .pricing-plan {
      border: 1px solid lighten($primary-color, 22%) !important;
    }
    .alert-primary {
      // color: var(--theme-default);
    }
    .project-box {
      background-color: lighten($primary-color, 22%);
    }
    .nav-tabs .nav-link.active {
      background-color: transparent;
    }
    .simplebar-mask {
      top: 10px;
    }
    .page-header {
      margin-left: 0px;
      width: calc(100% - 0px);
      &.close_icon {
        margin-left: 0px;
        width: calc(100% - 0px);
      }
      .header-wrapper {
        .nav-right {
          @media (max-width:1669px) {
            width: 50%;
          }
          @media (max-width:1428px) and (min-width:1200px) {
            width: 40%;
          }
        }
        .header-logo-wrapper {
          display: flex;
          align-items: center;
        }
        .logo-wrapper {
          display: block;
          margin-right: 30px;
        }
        .toggle-sidebar {
          display: block;
          position: relative;
          top: unset;
          right: unset;
          margin-right: 15px;
        }
      }
    }
    .page-body-wrapper {
      .page-title {
        background-color: transparent;
        padding-bottom: 0;
      }
      div.sidebar-wrapper {
        margin: 30px !important;
        top: 80px;
        bottom: 0;
        border-radius: 15px;
        .logo-wrapper,
        .logo-icon-wrapper {
          display: none;
        }
        .sidebar-main {
          .sidebar-links {
            height: calc(100vh - 160px);
          }
        }
        &.close_icon {
          .logo-icon-wrapper {
            display: none;
          }
          ~.page-body {
            margin-left: 0px;
          }
          ~footer {
            margin-left: 0px;
            margin-right: 0px;
          }
          &:hover {
            .logo-wrapper {
              display: none;
            }
          }
        }
      }
      .page-body {
        margin-left: 296px;
        .container-fluid {
          max-width: 1500px;
        }
      }
      .footer {
        margin-left: 360px;
        margin-right: 70px;
        border-radius: 10px;
        @media (max-width: 991px) {
          margin-right: 0;
          margin-left: 0;
        }
      }
    }
  }
  // material css
  &.material-type {
    position: relative;
    &::before {
      position: absolute;
      content: '';
      left: 0;
      width: 100%;
      height: 300px;
      // background-image: $gradient-bg-primary;
    }
    .card {
      box-shadow: 0 0 20px rgba($black, 0.05);
    }
    .page-header {
      position: relative;
      max-width: 1560px;
      margin: 50px auto 0;
      border-radius: 15px 15px 0 0;
      .header-wrapper {
        .left-header {
          width: 31%;
          @media (max-width:1600px) {
            width: 33%;
          }
          @media (max-width:1366px) {
            width: 38%;
          }
          @media (max-width:1199px) {
            width: 30%;
          }
        }
        .nav-right {
          @media (max-width:1600px) {
            width: 40%;
          }
          @media (max-width:1199px) {
            width: 45%;
          }
        }
      }
    }
    .page-body-wrapper {
      // background-image: url(../../images/other-images/boxbg.jpg);
      background-blend-mode: overlay;
      background-color: rgba($white, 0.5);
      .sidebar-wrapper {
        position: relative;
        max-width: 1560px;
        margin: 0 auto;
        top: unset;
        height: 56px;
      }
      .page-body {
        margin: 0 auto;
        max-width: 1560px;
        // background-color: $body-color;
        box-shadow: 0 0 20px 7px rgba(8, 21, 66, 0.05);
        border-radius: 0 0 15px 15px;
      }
    }
    .footer {
      margin-top: 40px;
    }
    &.compact-wrapper {
      .page-header {
        .header-wrapper {
          .left-header {
            width: 38%;
          }
          .nav-right {
            @media (max-width:991px) {
              width: 50%;
            }
          }
        }
      }
    }
  }
  &.advance-layout {
    background-blend-mode: overlay;
    // background-color: $main-body-color;
    .card {
      border-radius: 5px;
      box-shadow: none;
      border: 1px solid #ecf3fa;
    }
    .page-header {
      position: relative;
      background-color: $white;
      margin: 0 auto;
    }
    .page-body-wrapper {
      background: transparent;
      .sidebar-wrapper {
        position: relative !important;
        top: unset !important;
        height: unset;
        box-shadow: none;
        border-top: 1px solid #efefef;
        border-bottom: 1px solid #efefef;
        >div {
          overflow-x: hidden;
        }
      }
      .page-body {
        margin-top: 0 !important;
      }
    }
  }
}
@keyframes pagebody {
  from {
    opacity: 0;
    transform: scale3d(0.98, 0.98, 0.98) translate(-20px, -50px);
  }
  50% {
    opacity: 1;
  }
}
// sidebar filter
.md-sidebar {
  position: relative;
  .md-sidebar-toggle {
    display: none;
    width: fit-content;
    text-transform: capitalize;
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 1480px) {
  .page-wrapper {
    &.horizontal-wrapper {
      .page-body-wrapper {
        .sidebar-wrapper {
          .sidebar-main {
            .sidebar-links {
              .simplebar-wrapper {
                .simplebar-mask {
                  .simplebar-content-wrapper {
                    .simplebar-content {
                      >li {
                        .mega-menu-container {
                          right: -18%;
                          width: 91%;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1460px) {
  .page-wrapper {
    &.horizontal-wrapper {
      .page-body-wrapper {
        &.sidebar-wrapper {
          .sidebar-main {
            .sidebar-links {
              .simplebar-wrapper {
                .simplebar-mask {
                  .simplebar-content-wrapper {
                    .simplebar-content {
                      >li {
                        .mega-menu-container {
                          right: -54%;
                          width: 75%;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .sidebar-wrapper {
    .sidebar-main {
      .sidebar-links {
        .simplebar-wrapper {
          .simplebar-mask {
            .simplebar-content-wrapper {
              .simplebar-content {
                >li {
                  margin-right: 8px;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1366px) {
  .page-wrapper {
    &.horizontal-wrapper {
      .page-body-wrapper {
        &.sidebar-wrapper {
          .sidebar-main {
            .sidebar-links {
              .simplebar-wrapper {
                .simplebar-mask {
                  .simplebar-content-wrapper {
                    .simplebar-content {
                      >li {
                        .mega-menu-container {
                          right: -49%;
                          width: 70%;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .profile-dropdown {
    &.onhover-show-div {
      right: 0;
      left: unset !important;
      &:before,
      &:after {
        right: 10px !important;
        left: unset !important;
      }
    }
  }
}
@media screen and (max-width: 1199px) {
  .mobile-title {
    &.d-none {
      display: flex !important;
      justify-content: space-between;
      padding: 20px 30px 17px;
      border-bottom: 1px solid #ddd;
      margin: 0 -15px;
      h5 {
        // color: var(--theme-default);
      }
      svg {
        // stroke: var(--theme-default) !important;
      }
    }
  }
  .page-wrapper {
    &.horizontal-wrapper {
      .page-body-wrapper {
        &.sidebar-wrapper {
          .sidebar-main {
            .sidebar-links {
              .simplebar-wrapper {
                .simplebar-mask {
                  .simplebar-content-wrapper {
                    .simplebar-content {
                      >li {
                        .mega-menu-container {
                          right: -35%;
                          width: 56%;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (min-width: 992px) {
  .page-header {
    .header-wrapper {
      .nav-right {
        .notification-dropdown {
          top: 46px;
        }
      }
    }
  }
  .responsive-btn {
    display: none;
  }
  .page-wrapper {
    &.horizontal-wrapper {
      .page-body-wrapper {
        .sidebar-main {
          .sidebar-links {
            .simplebar-mask {
              top: 0;
            }
            .simplebar-wrapper {
              .simplebar-mask {
                .simplebar-content-wrapper {
                  .simplebar-content {
                    >li {
                      .sidebar-submenu {
                        width: 200px;
                        opacity: 0;
                        visibility: hidden;
                        border-radius: 5px;
                        transition: all 0.5s ease;
                        a {
                          .sub-arrow {
                            position: absolute;
                            right: 20px;
                            top: 8px;
                            // color: $light-semi-font;
                            font-weight: 100;
                            transition: all 0.5s ease;
                            font-size: 15px;
                          }
                        }
                        li {
                          .opensubmegamenu {
                            display: none;
                            border-radius: 4px;
                          }
                          &:hover {
                            .opensubmegamenu {
                              display: block;
                              position: absolute;
                              left: 200px;
                              background-color: $white;
                              top: -10px;
                              padding: 15px 0px 15px 0px;
                              box-shadow: 0 0 1px 0 #898989;
                            }
                          }
                          a {
                            &:hover {
                              .sub-arrow {
                                right: 16px;
                                transition: all 0.5s ease;
                              }
                            }
                          }
                        }
                      }
                      .mega-menu-container {
                        opacity: 0;
                        visibility: hidden;
                        transition: all 0.5s ease;
                      }
                      &.hoverd {
                        .sidebar-submenu {
                          opacity: 1;
                          visibility: visible;
                          margin-top: 8px;
                          transition: all 0.5s ease;
                        }
                      }
                      &:hover {
                        .mega-menu-container {
                          opacity: 1;
                          visibility: visible;
                          margin-top: 20px;
                          transition: all 0.5s ease;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .toggle-nav {
          display: none;
        }
        .mobile-back {
          display: none !important;
        }
      }
    }
  }
}
@media only screen and (min-width: 768px) {
  // material icon css
  .page-wrapper {
    &.material-icon {
      position: relative;
      &::before {
        position: absolute;
        content: '';
        left: 0;
        width: 100%;
        height: 200px;
        // background: $gradient-bg-primary;
      }
      .toggle-sidebar {
        color: rgba($white, 0.8);
        &:hover {
          >svg {
            color: $white !important;
            stroke: $white !important;
          }
        }
      }
      .page-header {
        position: relative;
        background: transparent;
        padding-left: 90px;
        max-width: 1660px;
        margin: 0 auto;
        width: calc(100% - 0px);
        box-shadow: none;
        .f-light {
          color: $white;
        }
        .header-wrapper {
          .nav-right {
            ul {
              li {
                svg {
                  stroke: $white;
                }
                .profile-dropdown {
                  li {
                    svg {
                      // stroke: $theme-body-font-color;
                    }
                  }
                }
              }
            }
            >ul {
              >li {
                color: $white;
                .notification-box,
                .cart-box {
                  svg {
                    stroke: $white;
                  }
                }
                >a {
                  >svg {
                    stroke: $white;
                  }
                }
                >svg {
                  stroke: $white;
                }
              }
            }
            .language-nav {
              .more_lang {
                // color: $theme-body-font-color;
              }
            }
            .onhover-show-div {
              // color: $theme-body-font-color;
            }
            &.right-header {
              ul {
                li {
                  .profile-media {
                    .flex-grow-1 {
                      p {
                        color: rgba($white, 0.7);
                      }
                    }
                  }
                }
              }
            }
          }
        }
        &.close_icon {
          padding-left: 0;
          margin: 0 auto !important;
        }
      }
      .page-body-wrapper {
        .page-body {
          margin-top: 0 !important;
          padding-left: 90px;
          max-width: 1660px;
          margin: 0 auto;
        }
        div.sidebar-wrapper {
          &.close_icon {
            ~.page-body {
              padding-left: 15px;
              margin: 0 auto !important;
            }
          }
        }
        .page-title {
          color: $white;
          background-color: transparent;
          border-bottom: none;
          box-shadow: none;
          .breadcrumb {
            .breadcrumb-item {
              a {
                svg {
                  stroke: $white;
                }
              }
              &.active {
                color: rgba($white, 0.6);
              }
              +.breadcrumb-item {
                &::before {
                  color: rgba($white, 0.8);
                }
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 992px) {
  // material icon css
  .page-wrapper {
    &.material-icon {
      &::before {
        height: 240px;
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .sidebar-wrapper {
    .menu {
      .brand-logo {
        img {
          width: 120px;
        }
      }
    }
  }
  .page-wrapper {
    .left-header {
      display: none;
    }
    .page-header {
      .header-wrapper {
        .serchinput {
          display: block !important;
        }
      }
    }
  }
}
@media screen and (max-width: 576px) {
  .toggle-nav {
    i {
      font-size: 20px;
    }
  }
}

.sidebar-wrapper{
  position: fixed;
  top: 0;
  z-index: 9;
  height: 100%;
  line-height: inherit;
  background: #2a3650;
  width: 252px;
  text-align: left;
  transition: .3s;
  box-shadow: 0 0 21px 0 rgba(89, 102, 122, .1);
  .sidebar-main{
    padding: 20px;
  }

  .sidebar-main-title {
    width: 100%;
  }
  .logout-btn{
    position: absolute;
    bottom: 20px;
    left: 20px;
    .btn{
    }
  }
}
/**=====================
  5.7 Sidebar CSS Ends
==========================**/